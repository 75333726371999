import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';



const ContactPage = () => {


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("CONTACTtmj", "template_11hrkzk", form.current, "6BnWXtAJp9KujfoUQ", "message2")
      .then((result) => {
          console.log(result.text);
          form.current.reset();
      }, (error) => {
          console.log(error.text);
      }).then(() => {
        window.alert("Email Sent! You may close this window now.")

      });
  };

  

  return (
    <div className="max-w-md mx-auto mt-[20vh] p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
      <form ref={form} onSubmit={sendEmail}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700">Email or Phone Number</label>
          <input
          placeholder="Enter a number or Email"
            type="text"
            id="name"
            name="from_name"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="message" className="block text-gray-700">Message</label>
          <textarea
          placeholder='Enter your Message Here'
            id="message"
            name="message"
            rows="5"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          value="send"
          className="w-full bg-[#1E42EE] text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactPage;
