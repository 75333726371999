import { useState, } from "react";
import taymarj from '../images/tmjLogo.png'
import "../styles/nav.css"

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const toggleNav = () => {
    setNavOpen(!navOpen);
    // Prevent scrolling when the menu is open
    document.body.style.overflow = navOpen ? "auto" : "hidden";
  };

  return (
    <header className="ml-[2.5%] absolute min-w-[95%] max-w-[95%] min-h-[75px] h-[12vh] navStyles tracking-wider bg-white flex flex-row items-center rounded-lg justify-between">
      <div className=" max-w-[40%] min-w-[125px] my-auto ">
        <a href="/"><img src={taymarj} className=" m-auto pl-[25px] max-h-[75px] min-h-[70px] " alt="TMJ LOGO" /></a>
      </div>
      <button onClick={toggleNav} className="absolute my-auto right-[15px] mr-[25px] fa-solid fa-bars text-[2em] md:hidden lg:hidden xl:hidden 2xl:hidden "></button>
      <div className={`w-full flex flex-row items-center justify-end ${navOpen ? " inline-block" : "hidden  overflow-x-hidden"}`}>
        <button onClick={toggleNav} className=" z-50 text-white text-[2em] closeMenuBtn absolute right-0 fa-solid fa-x pt-[30px] pr-[50px]"></button>
        <ul className={`absolute z-40 w-full text-white flex justify-center items-center flex-col leading-[15vh] text-[2em] left-[-2.5vw] top-0 h-screen w-screen dropdown bg-black`}>
          <li><a className="rounded-lg" href="/about">About Us</a></li>
          <li><a className="rounded-lg" href="/contact">Contact Us</a></li>
          <li><a className="rounded-lg" href="/services">Services</a></li>
        </ul>
      </div>
      <div className="float-right mr-[25px] navRtSide hidden md:flex lg:flex xl:flex 2xl:flex ">
        <ul className="closedNavBtns flex items-center flow-row">
          <li><a href="/about">About Us</a></li>
          <li><a href="/services">Services</a></li>
          <li><a href="/contact">Contact Us</a></li>
        </ul>
      </div>
    </header>)
};


export default Navbar;