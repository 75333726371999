import React from 'react';

const Services = () => {
  return (
    <div className="flex items-center w-screen justify-center h-screen">
  <div className="max-w-4xl mt-[30vh] md:mt-[50px] lg:mt-[50px] 2xl:mt-[50px] mx-auto h-auto min-h-[75%] overflow-y-scroll p-6 bg-white rounded-lg shadow-md">
    <h2 className="text-2xl font-bold mb-6">What We Offer</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="p-4 bg-gray-100 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Long Distance Transport</h3>
        <p className="text-gray-700">
        For services spanning across state lines or more than 70 miles, we utilize either our wedge trailer or 9-car hauler, depending on specifics, to transport your vehicle to its destination. Contact us for pricing details.
        </p>
      </div>
      <div className="p-4 bg-gray-100 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Short Distance Transport</h3>
        <p className="text-gray-700">
        For short-distance moves within the state or under 70 miles, we offer efficient and reliable transport solutions using our 5 or 9 car hauler. Contact us for pricing details and to schedule your move.
        </p>
      </div>
      <div className="p-4 bg-gray-100 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Enclosed Carry</h3>
        <p className="text-gray-700">
        For specialized transportation needs, including high-value or delicate vehicles, we offer the option of enclosed carriers for added protection and security. Contact us to discuss your specific requirements and receive a customized quote.
        </p>
      </div>
    </div>
  </div>
</div>
  );
};

export default Services;
