import React from 'react';
import tmjLogo from '../images/tmjLogo.png';

const About = () => {
    return (
        <div className="mt-[10vh] lg:mt-[5vh] xl:mt-[5vh] 2xl:mt-[5vh] flex flex-col md:flex-row items-center justify-center min-h-screen">
            <div className="md:w-1/2 p-8">
                <h2 className="text-3xl font-bold mb-4">About Us</h2>
                <p className="text-gray-700">
                    Founded by Justin Taylor, TayMar J Transportation was born out of a passion for cars and a love for the open road. We are a young, dynamic team driven by a desire to provide exceptional service and positive interactions in every transaction.
                    At our core, we believe in the power of empathy. We understand the importance of each vehicle we transport, recognizing that behind every car is a story and a person relying on our service. That's why we strive to not only meet but exceed expectations, ensuring every customer feels valued and respected.
                    Affordability is another cornerstone of our business. Coming from a background where every dollar counts, we understand the financial strain of vehicle transportation. That's why we are committed to providing affordable prices without compromising on the quality of our service.
                    As a New Jersey-based company in the auto transportation industry, we specialize in offering both long and short-distance transportation services for non-commercial cars and trucks. Our goal is to cater to all budgets, working closely with our customers to find solutions that fit their needs.
                    Our vision is to grow into a full fleet of 15+ trucks, expanding our reach and capacity to serve more customers across the region. As of now, our company is solely run and operated by Justin Taylor, ensuring a hands-on approach and personalized service that sets us apart.
                    Join us on our journey as we strive to redefine the standards of auto transportation, one mile at a time.
                </p>
            </div>
            <div className="md:w-1/2">
                <img src={tmjLogo} alt="About Us" className="w-full h-full object-cover" />
            </div>
        </div>
    );
};

export default About;
