import '../styles/home.css'
import { useEffect, useRef, useState } from 'react';
import comingSoon from "../images/comingSoon2.png";
import squiggleWithBg from "../images/updatedHomeAbt.jpg";

const Home = () => {

    const [mobile, setMobile] = useState(true); // Corrected the order of destructuring
    useEffect(() => {
        function handleResize() {
            const notMobile = window.innerWidth <= 718;
            notMobile ? setMobile(false) : setMobile(true);
        }

        // Call the function once to set the initial state
        handleResize();

        // Add event listener to window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty dependency array to ensure effect runs only once


    const sectionRef = useRef(null)

    useEffect(() => {
        const section = sectionRef.current;

        const handleScroll = () => {
            const rect = section.getBoundingClientRect();
            if (rect.top < window.innerHeight) {
                section.classList.add('fadeIn');
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])



    return (
        <div className="h-screen w-screen mt-[78px] flex flex-col  ">
            <main className=" flex-grow w-screen mx-auto z-1  ">
                <section className="w-full h-[70vh] ">

                    <div className=" h-full w-full relative homeBg bg-cover bg-no-repeat">
                        <div className="h-[100%] flex flex-around ">
                            <img className="m-auto md:w-[85vw] lg:w-[85vw] xl:w-[80vw] " src={comingSoon} alt="Coming Soon..."></img>
                        </div>
                        {/* <section className={`absolute mt-[10vh] ${mobile ? "md:h-[85vh] lg:h-[85vh] xl:h-[85vh] md:w-[50%] lg:w-[50%] xl:[50%] bg-black}" : "hidden"} `}>
                            <div className="flex flex-col w-full h-full homeBanner text-white">
                                <h1 className="">Fast</h1>
                                <h1 className="">And</h1>
                                <h1 className="">Affordable</h1>
                            </div>
                        </section>
                        <article className="homeBannerTxt absolute h-[75%]  rounded-lg md:w-[45%] lg:w-[45%] xl:w-[45%] 2xl:w-[45%]  text-auto transform top-1/2 left-1/2 md:right-0 lg:right-0 xl:right-0 2xl:right-0 -translate-x-1/2 -translate-y-1/2 md:-translate-x-0 lg:-translate-x-0 xl:-translate-x-0 2xl:-translate-x-0 p-[10px] ">
                            <header className="my-[2.5%] text-[1.75em] ">
                                <p>Get an instant quote</p>
                            </header>
                            <sub className=" tracking-tight w-[100vw]">Fill out this short form and <br /> get an instant offer sent to your <br />email or phone</sub>
                            <form className="w-[90%] mx-auto homeBannerForm">
                                <h3>Email Address</h3>
                                <input type='text'></input>
                                <h3>Phone Number</h3>
                                <input type='text'></input>
                                <h3>Pickup Location</h3>
                                <input type='text'></input>
                                <h3>Destination</h3>
                                <input type='text'></input>
                                <br />
                                <button type='submit' className="border my-[15px] text-[1.5em] border-solid rounded-md">Send My Offer</button>
                            </form>
                        </article> */}
                        <div className="w-full text-center relative bottom-10">
                            <h4>
                                <span style={{ display: "inline-block", verticalAlign: "middle" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ fontSize: "20px" }}>
                                        <path d="M12 5v14M5 12l7 7 7-7" />
                                    </svg>
                                </span>
                                <span className="stayConnected" style={{ display: "inline-block", verticalAlign: "middle" }}>Stay Connected on Social Media&nbsp;</span>
                                <span style={{ display: "inline-block", verticalAlign: "middle" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ fontSize: "20px" }}>
                                        <path d="M12 5v14M5 12l7 7 7-7" />
                                    </svg>
                                </span>
                            </h4>
                        </div>
                    </div>
                </section>
                <section className="h-[20vh] bg-black flex justify-around shadow-lg ">
                    <ul className="w-full items-center flex text-[32px] justify-around space-x-4">
                        <li>
                            <a href="https://www.instagram.com/tmjtransport" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400 ">
                                <i className="fab igShareBtn fa-instagram  social-icon"></i>
                            </a>
                        </li>
                        <li>

                            <a href="https://www.linkedin.com/company/taymar-j-transportation-llc/" target="_blank" rel="noopener noreferrer" className="text-white  hover:text-[#3b5898c8] transition-colors">
                                <i class="fab fa-brands fa-linkedin-in"></i>
                            </a>
                        </li>
                        <li>

                            <a href="https://www.facebook.com/profile.php?id=61558049060467" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#3b5898b3] transition-colors">
                                <i className="fab fa-facebook  social-icon"></i>
                            </a>
                        </li>
                        {/* <li>
                            <a href="/" target="_blank" rel="noopener noreferrer" className="text-white text-[32px]">
                                <i class="fa-brands fa-tiktok"></i>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <i class="fa-brands fa-twitter text-white"></i>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <i class="fa-brands fa-youtube text-white"></i>
                            </a>
                        </li> */}
                    </ul>
                </section>
                <section className="h-[85vh] w-[100vw] my-[0px] py-[0px] " ref={sectionRef} >
                    <div className="relative w-full h-full flex items-center justify-center text-center row bg-white homeBannerForm">
                        <div className="leftSideTextCommitmentSect pt-[25px] overflow-scroll w-[90%] h-full md:float-left lg:float-left xl:float-left 2xl:float-left border-bottom border-black-600 shadow-lg ">
                            <h2 className='ourCommitmentTitle flex items-center justify-center text-[24px] my-auto min-h-[102px] max-h-[102px] tracking-widest'>Our Commitment To You <br/> ( Valued Customers )</h2>
                            <p className='tracking-wider mt-[25px] '>At our core, we are committed to fostering a culture of empathy, where every interaction reflects our deep respect for your needs and concerns. We believe in offering affordable prices without compromising on quality, ensuring that our services remain accessible to all. With a steadfast dedication to on-time deliveries, we honor your trust by consistently meeting deadlines and exceeding expectations. Backed by top-notch customer support, our team goes above and beyond to provide personalized assistance and guidance at every step of your journey. Lastly but not least, we prioritize sustainability, innovation, and transparency to ensure a holistic and enriching experience for our valued customers.</p>
                            <p>- TayMar Team</p>
                        </div>
                        <img src={squiggleWithBg} alt="Ontime Deliveries, Affordable Prices Always, Top-Notch Customer Support" className={`bannerRtSide float-right ${mobile ? " md:w-[50%] md2:w-[50%] lg:w-[50%] xl:[50%]}" : "hidden"} `}></img>
                    </div>
                </section>
            </main>
            <footer style={{ width: "100vw", backgroundColor: "#f4f4f4", padding: "20px 0", textAlign: "center" }}>
                <br />
                <div>
                    <p>&copy; 2024 Taymar J Transportation. All rights reserved.</p>
                    <p>Designed and developed by Taymar J Transportation LLC</p>
                </div>
                <div>
                    <a href="/about">About Us</a> | <a href="/services">Services</a> | <a href="/contact">Contact</a>
                </div>
                <div>
                    {/* <p>525 Route-73 N, suite 104, Marlton, USA</p> */}
                    <p>Phone: <a href="tel:6099686388">+1 (609)-968-6388</a></p>
                    <p>Email: <a href="mailto:justin.taylor@tmjtransport.net">justin.taylor@tmjtransport.net</a></p>
                </div>
                <script type="text/javascript" src="https//cdn.ywxi.net/js/1.js" async></script>
            </footer>
        </div>
    )
}

export default Home;